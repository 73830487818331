import React, {useState, useContext} from 'react'
import Sidebar from './Sidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import { getToken } from "../../services/LocalStorage"
import "./UpdateBlog.css"
import BlogContext from "../../context/BlogContext";

const UpdateBlog = () => {
    
  const { base } = useContext(BlogContext);

    const [isLoading, setIsLoading] = useState(false);

    const toastOptions = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark"
    }

    const spinner = <PulseLoader
        color="white"
        loading={isLoading}
        size={8}
        aria-label="Loading Spinner"
        data-testid="loader"
    />

    const handleSumbit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const actualData = {
            id: e.target.id.value,
            slug: e.target.slug.value,
            title: e.target.title.value,
            category: e.target.category.value,
            description: e.target.description.value,
            markdown: e.target.markdown.value
        }
        fetch(`${base}/api/v1/blog/${actualData.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "authorization": `${getToken()}`
            },
            body: JSON.stringify(actualData)
        }).then(async (res) => {
            setIsLoading(false)
            const data = await res.json()
            if (data.status === "failed") {
                toast.error(data.message, toastOptions);
            }
            else {
                toast.success(data.message, toastOptions)
                window.open('/blog/all', "_self")
            }
        })
    }

    return (
        <>
            <Sidebar />
            <div className="update-container">
                <div className="update-content">
                    <h1 className="update-title">Update Blog</h1>
                    <form onSubmit={handleSumbit}>
                        <span id="update-name">
                            <label htmlFor="update-userName">ID</label>
                            <input type="text" id="update-userName" name="id" className="update-input" />
                        </span>
                        <span id="update-name">
                            <label htmlFor="update-userName">Slug</label>
                            <input type="text" id="update-userName" name="slug" className="update-input" />
                        </span>
                        <span id="update-age">
                            <label htmlFor="update-userAge">Title</label>
                            <input type="text" id="update-userAge" name="title" className="update-input" />
                        </span>
                        <span id="update-add">
                            <label htmlFor="update-userAdd">Category</label>
                            <input type="text" id="update-userAdd" name="category" className="update-input" />
                        </span>
                        <span id="update-mail">
                            <label htmlFor="update-userMail">Description</label>
                            <input type="text" id="update-userMail" name="description" className="update-input" />
                        </span>
                        <span id="update-phone">
                            <label htmlFor="update-userPhone">Markdown</label>
                            <textarea type="text" id="update-userPhone" name="markdown" rows="12" style={{fontSize:"14px"}} />
                        </span>
                        <button type="submit" className="update-btn">{isLoading ? spinner : "Sumbit"}</button>
                    </form>
                    <ToastContainer/>
                </div>
            </div>
        </>
    )
}

export default UpdateBlog