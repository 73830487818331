import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {getToken} from './services/LocalStorage';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogState from './context/BlogState';
import Login from './components/admin/Login';
import AllBlogs from './components/admin/AllBlogs';
import UploadBlog from './components/admin/UploadBlog';
import UpdateBlog from './components/admin/UpdateBlog';
import Register from './components/admin/Register';
import About from './pages/About';
import Error from './pages/Error';
import Categories from './pages/Categories';
const App = () => {

  let token = getToken();

  return (
    <>
      <BlogState>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/sitemap.xml" element={<sitemap />} />
            <Route path="/blogpost/:slug" element={<Blog />} />
            <Route path="/register" element={!token ? <Register /> : <Navigate to="/blog/all" />} />
            <Route path="/login" element={!token ? <Login /> : <Navigate to="/blog/all" />} />
            <Route path="/blog/all" element={token ? <AllBlogs /> :  <Navigate to="/login" />} />
            <Route path="/blog/upload" element={token ? <UploadBlog /> :  <Navigate to="/login" />} />
            <Route path="/blog/update" element={token ? <UpdateBlog /> :  <Navigate to="/login" />} />
            <Route path="/*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </BlogState>
    </>
  )
}

export default App