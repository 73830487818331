import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader';

const Spinner = ({ color }) => {

    const spinner = (
        <PulseLoader color={color ? color : 'white'} size={8} aria-label="Loading Spinner" data-testid="loader" />
    );

    return (
        <div>{spinner}</div>
    )
}

export default Spinner