import React, { useContext } from 'react';
import BlogContext from '../context/BlogContext';
import './Home.css';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Spinner from '../components/Spinner'

const Home = () => {

  const { blogpost } = useContext(BlogContext);

  console.log(blogpost.length);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }
  return (
    <>
      <Navbar />
      <div className="container">
        <div className='myBlogs'>
          <div className="compo-container">
            <div className="box">
              <h1 className='latest-blog'>Latest</h1>
              {blogpost.length === 0 ? (
                <div className='loading'><Spinner color={'black'}/></div>
              ) : (
                blogpost.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="grid-items">
                        <div className="left">
                          {formatDate(data.createdAt)}
                        </div>
                        <div className="right">
                          <Link to={`/blogpost/${data.slug}`}>
                            <h1 className="title">{data.title}</h1>
                          </Link>
                          <p className="cat">{data.category.toUpperCase()}</p>
                          <p className="desc">{data.description.substring(0, 180)}</p>
                          <div className="read_more">
                            <Link className="read_more_link" to={`/blogpost/${data.slug}`}>
                              <p className="read_more_inner">Read More</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home