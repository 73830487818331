import React, { useContext } from 'react'
import BlogContext from '../../context/BlogContext'
import DisplayBlog from './DisplayBlog';
import Sidebar from './Sidebar';

const AllBlogs = () => {

    const { blogpost } = useContext(BlogContext)

    return (
        <>
            <Sidebar />
            {/* <ul> */}
            {blogpost.map((data) => {
                return (
                    <DisplayBlog key={data._id} id={data._id} slug={data.slug} title={data.title} description={data.description} category={data.category} markdown={data.markdown} />
                )
            })}
            {/* </ul> */}
        </>
    )
}

export default AllBlogs