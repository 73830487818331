import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import "../BlogItems.css";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/Codeblock";
import { getToken } from "../../services/LocalStorage";
import BlogContext from "../../context/BlogContext";

const DisplayBlog = (props) => {
  
    const { base } = useContext(BlogContext);

  const { id, slug, title, description, category, markdown, createdAt } = props;

  const [isLoading, setIsLoading] = useState(false);

  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const deleteBlog = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let url = `${base}/api/v1/blog/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: `${getToken()}`,
      },
    }).then(async (res) => {
      const data = await res.json();
      setIsLoading(false);
      if (data.status === "failed") {
        toast.error(data.message, toastOptions);
      } else {
        toast.success(data.message, toastOptions);
        window.open("/blog/all", "_self");
      }
    });
  };

  return (
    <>
      <p>
        <div
          className="grid-items"
          style={{
            border: "1px solid black",
            backgroundColor: "rgb(230, 230, 255)",
            margin: "5rem",
          }}
        >
          <div className="top">
            <p className="date">{id}</p>
            <p className="date">{slug}</p>
            <h2 className="title">{title}</h2>
            <p className="date">{createdAt}</p>
            <p className="cat">{category.toUpperCase()}</p>
            <p className="desc">{description}</p>
            <p className="desc">
              <ReactMarkdown components={CodeBlock}>{markdown}</ReactMarkdown>
            </p>
            <div>
              <button
                className="upload-btn"
                style={{ backgroundColor: "red", marginLeft: "10px" }}
              >
                <a
                  href="/blog/update"
                  className="upload-btn"
                  style={{ backgroundColor: "red" }}
                >
                  Edit
                </a>
              </button>
              <button
                className="upload-btn"
                style={{ backgroundColor: "red", marginLeft: "10px" }}
                onClick={deleteBlog}
              >
                <a
                  href="/blog/update"
                  className="upload-btn"
                  style={{ backgroundColor: "red" }}
                >
                  {isLoading ? spinner : "Delete"}
                </a>
              </button>
            </div>
            <ToastContainer />
          </div>
        </div>
      </p>
    </>
  );
};

export default DisplayBlog;
