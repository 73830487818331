import React, { useState, useEffect } from "react";
import BlogContext from "./BlogContext";

const host = "https://apibackendroyad.vercel.app/api/v1/blog/all"
// const host = "http://localhost:1000/api/v1/blog/all";

const BlogState = (props) => {
  const myBlogs = [];
  const [blogpost, setBlogpost] = useState(myBlogs);

  const [base, setBase] = useState("https://apibackendroyad.vercel.app/");

  useEffect(() => {
    fetch(host, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (res) => {
      const data = await res.json();
      setBlogpost(data.message);
    });
  }, []);


  return (
    <BlogContext.Provider value={{ blogpost, base }}>
      {props.children}
    </BlogContext.Provider>
  );
};

export default BlogState;
