import React from 'react'

const Error = () => {
  return (
    <>
        <div className='error'>
            404 Error
        </div>
    </>
  )
}

export default Error