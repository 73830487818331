import React, { useState, useEffect } from 'react'
import "./Navbar.css"

const Navbar = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const [isOpen, setIsOpen] = useState(false);

    const [style, setStyle] = useState("trans1");

    const changeStyle = () => {
        if (style === "trans1") {
            setStyle("trans2");
        } else {
            setStyle("trans1");
        }
    };

    return (
        <>
            {windowSize[0] >= 800 ? (
                <div className='main_nav flex justify-around items-center'>
                    <h2 className='navbar_left'>
                        <a href="/" className='color-black'>Dev Blogs</a>
                    </h2>
                    <div className='navbar_right'>
                        <ul className='navbar_ul flex justify-around '>
                            <li><a href='/#'>Home</a></li>
                            <li><a href='/categories'>Categories</a></li>
                            <li><a href='/about'>About</a></li>
                        </ul>
                    </div>
                </div>
            ) : (
                <>
                    <nav className="navbar_sidebar_top">
                        <h2>
                            <a href="/" className="navbar_left">
                            Dev Blogs
                            </a>
                        </h2>
                        <div className="humburger" onClick={changeStyle}>
                            <p
                                className="humburger_btn"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-gray-900 dark:text-gray-100"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            </p>
                        </div>
                    </nav>
                    <div
                        className={`sidebar + ${style}`}
                    >
                        <div className="links mb-10">
                            <ul className="flexx">
                                <li className="my-5">
                                    <a
                                        href="/#"
                                        className="navbar_textstyle"
                                    >
                                        Home
                                    </a>
                                </li>
                                <li className="my-5">
                                    <a
                                        href="/categories"
                                        className="navbar_textstyle"
                                    >
                                        Categories
                                    </a>
                                </li>
                                <li className="my-5">
                                    <a
                                        href="/about"
                                        className="navbar_textstyle"
                                    >
                                        About
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Navbar