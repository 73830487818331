import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Categories.css'

const Categories = () => {

    const [blogData, setBlogData] = useState([]);
    const host = "http://localhost:1000/api/v1/blog/all";

    useEffect(() => {
        fetch(host, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        }).then(async (res) => {
            const data = await res.json();
            setBlogData(data.message);
        });
    }, [host]);

    return (
        <>
            <Navbar />
            <div className='category_container'>
                <div className='categories_title'>All Categories</div>
                {/* <hr/> */}
                <div className='cat_inside'>
                    {blogData.map((data, index) => {
                        return (
                            <div key={index} className='categories_link'>
                                <Link to={`/blogpost/${data.slug}`} className='categories_link_single'>{data.category}</Link>
                            </div>
                        );
                    })
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Categories