import React,{ useState, useContext } from 'react'
import Navbar from '../Navbar'
import './Login.css'
import { ToastContainer, toast } from 'react-toastify';
import { storeToken } from "../../services/LocalStorage"
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import BlogContext from "../../context/BlogContext";

const Login = () => {

  const { base } = useContext(BlogContext);

  const [isLoading, setIsLoading] = useState(false);

  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  
  const spinner = <PulseLoader
    color="white"
    loading={isLoading}
    size={8}
    aria-label="Loading Spinner"
    data-testid="loader"
  />

  const handleSumbit = async (e) => {
    e.preventDefault();
    const actualData = {
      email: e.target.email.value,
      password: e.target.password.value
    }
    setIsLoading(true)
    let url = `${base}/api/v1/auth/login`
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(actualData)
    }).then(async (res) => {
      const data = await res.json();
      setIsLoading(false)
      if (data.status === "failed") {
        toast.error(data.message, toastOptions);
      }
      else {
        toast.success(data.message, toastOptions);
        storeToken(data.token)
        window.open('/blog/all', "_self")
      }
    })
  }


  return (
    <>
      <Navbar />
      <div className="login-main">
        <div className="login-login">
          <form onSubmit={handleSumbit}>
            <div className="login-top">
              Sign In
            </div>
            <div className="login-middle">
              <label htmlFor="email" className="login-label">Username</label>
              <input type="email" id="email" className="login-input" name="email"/>
              <label htmlFor="password" className="login-label">Password</label>
              <input type="password" id="password" className="login-input" name="password"/>
            </div>
            <div className="login-bottom">
              <button className="login-btn" type="sumbit">{isLoading ? spinner : "Login"}</button>
            </div>
          </form>
          <ToastContainer/>
        </div>
      </div>
    </>
  )
}

export default Login