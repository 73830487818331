import "./Blog.css"
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from "react-router";
import BlogContext from '../context/BlogContext'
import ReactMarkdown from 'react-markdown'
import CodeBlock from "../components/Codeblock"

const Blog = () => {

    const { blogpost } = useContext(BlogContext)

    const { slug } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        let blogdata = blogpost.find((blog) => blog.slug === String(slug));
        setBlog(blogdata);
    }, [blogpost, slug])

    return (
        <>
            {blog ?
                <>
                    <div className="blog-container">
                        <div className="main">
                            <span className='back'>
                                <Link to='/'>
                                    &larr;Go Back
                                </Link>
                            </span>
                            <div className="blog-container">
                                <div className="blog-content">
                                    <div className="blogData">
                                        <ReactMarkdown components={CodeBlock}>
                                            {blog.markdown}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : <>Loading...</>
            }
        </>
    )
}

export default Blog