import React from 'react'
import { Link } from 'react-router-dom';
import "./Sidebar.css"

const Sidebar = () => {
    return (
        <>
            <nav className="sidebar-aside" aria-label="Sidebar">
                <div className="sidebar-main">
                    <div className="sidebar-main-title">
                        <Link to="/" className="sidebar-main-link">
                            <span className="sidebar-title">ROYAD Blog</span>
                        </Link>
                    </div>
                    <ul className="sidebar-ul">
                        <li className="sidebar-link-li">
                            <Link to="/blog/all" className="sidebar-links">
                                <span className="sidebar-li">All Blogs</span>
                            </Link>
                        </li>
                        <li className="sidebar-link-li">
                            <Link to="/blog/upload" className="sidebar-links">
                                <span className="sidebar-li">Upload Blog</span>
                            </Link>
                        </li>
                        <li className="sidebar-link-li">
                            <Link to="/blog/update" className="sidebar-links">
                                <span className="sidebar-li">Update Blog</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Sidebar